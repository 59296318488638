// 统一请求路径前缀在libs/axios.js中修改
import {postRequest } from '@/libs/axios';

// 登陆
export const login = (params) => {
    return postRequest('/api/login/doLogin', params)
}

// 获取全部用户数据
export const getUserAll = (params) => {
    return postRequest('/api/user/userAll', params)
}

//获取菜单列表
export const getPermissionsAll = (params) => {
    return postRequest('/api/getPermissionsAll', params)
}

// 添加menu数据
export const addMenu = (params) => {
    return postRequest('/api/addMenu', params)
}

// 修改menu数据
export const updateMenu = (params) => {
    return postRequest('/api/updateMenu', params)
}

// 删除menu数据
export const delMenu = (id) => {
    return postRequest('/api/delMenu', {id: id})
}

// 获取全部角色
export const getRoleAll = (params) => {
    return postRequest('/api/getRoleAll', params)
}

// 获取角色菜单最后位的标记 设置菜单
export const setMenuTree = (params) => {
    return postRequest('/api/setMenuTree', params)
}

//对应角色更新菜单
export const updateRoleMenuTree = (params) => {
    return postRequest('/api/updateRoleMenuTree', params)
}

//角色开关
export const swichRole = (params) => {
    return postRequest('/api/swichRole', params)
}

// 删除角色
export const delRole = (id) => {
    return postRequest('/api/delRole',  {id: id})
}

// 添加角色
export const putRole = (params) => {
    return postRequest('/api/putRole', params)
}
// 添加用户或者修改
export const putUser = (params) => {
    return postRequest('/api/user/putUser', params)
}

// 删除用户
export const delUser = (id) => {
    return postRequest('/api/user/delUser', {id: id})
}


// 获取全部用户数据
export const findMemberPageList = (params) => {
    return postRequest('/api/member/findMemberPageList', params)
}

export const findMemberList = (params) => {
    return postRequest('/api/member/findMemberList', params)
}

export const addMember = (params) => {
    return postRequest('/api/member/add', params)
}

export const delMember = (id) => {
    return postRequest('/api/member/delete',  {id: id})
}

export const editMember = (params) => {
    return postRequest('/api/member/edit', params)
}

export const addVoucher = (params) => {
    return postRequest('/api/voucher/add', params)
}

export const findVoucher = (params) => {
    return postRequest('/api/voucher/find', params)
}

export const addOrder = (params) => {
    return postRequest('/api/order/add', params)
}

export const getOrderAll = (params) => {
    return postRequest('/api/order/getOrderAll', params)
}

// 获取短信模板
export const findTemplatePageList = (params) => {
    return postRequest('/api/sms/findTemplatePageList', params)
}

//新增短信模板
export const addSMSTemplate = (params) => {
    return postRequest('/api/sms/addTemplate', params)
}

//新增短信模板
export const updateSMSTemplate = (params) => {
    return postRequest('/api/sms/updateTemplate', params)
}

//提交审核短信模板
export const auditTemplate = (id) => {
    return postRequest('/api/sms/audit', {id: id})
}

//删除短信模板
export const deleteTemplate = (id) => {
    return postRequest('/api/sms/delete', {id: id})
}

//查询短信模板审核状态
export const sysnTemplate = (id) => {
    return postRequest('/api/sms/sysn', {id: id})
}

//查询短信模板
export const findTemplate = (id) => {
    return postRequest('/api/sms/findTemplate', {id: id})
}

//发送短信任务添加
export const addTask = (params) => {
    return postRequest('/api/sms/addTask', params)
}

//发送回访短信任务添加
export const addReturnVisitTask = (params) => {
    return postRequest('/api/sms/addReturnVisitTask', params)
}


//回访记录查询
export const msgRecord = (params) => {
    return postRequest('/api/sms/msgRecord', params)
}

// 获取分页任务数据
export const findTaskPageList = (params) => {
    return postRequest('/api/task/findTaskPageList', params)
}

// 获取任务开关操作
export const switchTask = (params) => {
    return postRequest('/api/task/switch', params)
}

// 删除订单
export const delOrder = (id) => {
    return postRequest('/api/order/delete',  {id: id})
}