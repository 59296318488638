<template>
  <div class="login-page">
    <div class="content-area">
      <Row>
        <Col span="12">
          <div class="left-area">
            <div class="login-title-icon"></div>
            <div class="login-form-area">
              <div class="title">欢迎到</div>
              <div class="title" style="margin-bottom:10px">搭个圈后台管理系统</div>
              <Form ref="formInline" :model="formInline" :rules="ruleInline">
                <FormItem prop="username">
                  <div class="label">账号</div>
                  <Input type="text" v-model="formInline.username" placeholder="请输入用户名"></Input>
                </FormItem>
                <FormItem prop="password">
                  <div class="label">密码</div>
                  <Input type="password" v-model="formInline.password" placeholder="请输入密码"></Input>
                </FormItem>
                <FormItem>
                  <Checkbox v-model="remember">记住我</Checkbox>
                </FormItem>
                <FormItem>
                  <Button
                    class="submitBtn"
                    type="primary"
                    @click="handleSubmit('formInline')"
                    :loading="login.click"
                  >{{login.text}}</Button>
                </FormItem>
              </Form>
            </div>
            <div class="company">Copyright © 2019-2039 Dagequan. All Rights Reserved</div>
          </div>
        </Col>
        <Col span="12"></Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/index";
import md5 from 'js-md5';
import navJSON from './nav.json';
export default {
  data() {
    return {
      login: {
        click: false,
        text: '登 录'
      },
      formInline: {
        username: '',
        password: ''
      },
      ruleInline: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 5, max: 15, message: '长度必须5-15个字符', trigger: 'blur' }
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请输入密码'));
              } else {
                callback();
              }
            }, trigger: 'blur'
          }
        ]
      },
      remember: false,
    };
  },
  created() {
    if (localStorage.getItem('remember')) {
      this.remember = true
      this.formInline.username = localStorage.getItem('username')
    }
  },
  methods: {
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.login.click = true
          this.login.text = '正 在 登 录'
          // const loading = this.$loading({
          //   lock: true,
          //   text: '正在登录...',
          //   spinner: 'el-icon-loading',
          //   background: 'rgba(0, 0, 0, 0.7)'
          // });
          login({
            username: this.formInline.username,
            password: md5(this.formInline.password)
          }).then(res => {
            if (this.remember) {
              localStorage.setItem("username", this.formInline.username);
              localStorage.setItem("remember", true);
            } else {
              // 如果未勾选"记住我"，则清除本地存储中的用户名和密码
              localStorage.removeItem("username");
              localStorage.removeItem("remember");
            }
            // loading.close();
            this.login.click = false
            this.login.text = '登 录'
            if (res.success == true) {
              this.setSessionStore("accessToken", res.result.token)
              this.$Message.success('登录成功!');
              // this.$store.commit('SET_NAVS', res.result.user.menuList)
              this.$store.commit('SET_NAVS', navJSON)

              this.$store.commit("SET_USER_INFO", res.result.user)
              // this.$store.commit('SET_USER_INFO', { userName: '管理员' })
              this.$router.replace({ path: '/index' })
            }
          })
        } else {
          return false
        }
      })
    }
  }
}

</script>
<style lang='scss'>
.login-page {
  height: 100%;
  width: 100%;
  // background: url("../../assets/images/login-back-ground.png") no-repeat center
  //   center;
  // background-size: 100% 100%;
  text-align: center;
  .content-area {
    width: 100%;
    height: 100%;
    background: url("../../assets/images/login-bg.png") no-repeat right center;
    background-size: 50% 100%;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // margin-top: -300px;
    // margin-left: -550px;
    // width: 1100px;
    // height: 600px;
    // background: url("../../assets/images/login-back-ground_accessories.png")
    //   no-repeat center center;
    // background-size: 100% 100%;
    .left-area {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      height: 100vh;
    }
    .login-title-icon {
      width: 400px;
      height: 60px;
      background: url("../../assets/images/login_logo.jpg") no-repeat left
        center;
    }
    .login-form-area {
      width: 400px;
      margin: 100px auto 80px;
      text-align: left;
      .title {
        margin-bottom: 4px;
        width: 400px;
        text-align: left;
        font-size: 24px;
        color: #0d1d54;
        font-weight: bold;
      }
      .ivu-input.ivu-input-default {
        height: 40px;
        line-height: 40px;
      }
      .label {
        font-size: 16px;
        color: #404b7c;
      }
    }
    .submitBtn {
      width: 400px;
      height: 40px;
      border-radius: 6px;
      margin-top: 10px;
      // -webkit-box-shadow: 0px 3px 3px #c8c8c8;
      // box-shadow: 0px 3px 3px #c8c8c8;
      background: #005efe;
    }
    .company {
      text-align: center;
      // position: absolute;
      // bottom: 20px;
      // right: 20px;
      font-size: 14px;
      color: #9a9a9a;
      letter-spacing: 0.5px;
    }
  }
}
</style>